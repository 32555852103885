<template>
  <div class="terms">
    <h1 class="title">Vancheen个人用户服务协议</h1>
    <p class="titleInfo">Individual User Service Agreement</p>
    <p class="info">(Updated on NOV 15, 2021)</p>
    <p class="s1">本协议由你与迎客商服（广州）控股有限公司（以下简称“我们”）在中国广东省广州市订立。</p>
    <p
      class="s1"
    >This agreement is entered into between you and Prolinker Holding (Guangzhou) Co., Ltd. (hereinafter referred to as "we") in Guangzhou, Guangdong Province, China.</p>
    <p
      class="s1"
    >【慎重阅读】请您确认，您应当具备你所在国家法律规定的与您行为相适应的民事行为能力，如您不具备相应的民事行为能力，请您停止使用Vancheen。请您务必审慎阅读、充分理解各条款内容，特别是重要、免除或者限制责任的条款、法律适用和争议解决条款。</p>
    <p
      class="s1"
    >[Careful Reading] Please confirm that you should have the civil capacity applicable to your behavior stipulated by the laws of your country. If you do not have the corresponding civil capacity, please stop using Vancheen. You must carefully read and fully understand the contents of each clause, especially the important, exemption or limitation of liability, application of law and dispute resolution clauses.</p>
    <p
      class="s1"
    >【特别提示】当您阅读并同意协议后，即表明您已充分阅读、理解并接受本协议的全部内容，本协议即产生法律约束力，您承诺接受并遵守本协议的约定，自愿接受商家的推广委托，注册成为Vancheen店主。阅读过程中，如您不同意本协议及其补充协议中的任意条款约定，请您立即停止使用或退出登录。</p>
    <p
      class="s1"
    >[Special Note] When you read and agree to the Agreement, it means that you have fully read, understood and accepted all the contents of this agreement, and this agreement will be legally binding. You promise to accept and abide by the Agreement, voluntarily accept the promotion entrustment of the merchant and register as the shop owner of Vancheen. During reading, if you do not agree with any terms in this Agreement and its supplementary agreement, please stop using or log out immediately.</p>
    <h2 class="sTitle">一、定义</h2>
    <h2 class="sTitle">1. Definition</h2>
    <p class="s1">1.1 您：是指自愿接受商家的推广委托，使用Vancheen APP开通独立线上店铺，帮助商家向消费者推荐商品、提供服务、促成交易的商品推广服务者。</p>
    <p
      class="s1"
    >1.1 The“you”here refers to the commodity promotion service provider who voluntarily accepts the promotion entrustment of the merchant, uses Vancheen app to open an independent online store, and helps the merchant recommend goods, provide services and facilitate transactions to consumers.</p>
    <p
      class="s1"
    >1.2 Vancheen：是指由迎客商服（广州）控股有限公司及/或关联企业运营并享有知识产权或其他权利的提供商品或服务信息展示、在线服务及在线商品交易等经营活动的平台，产品形式包括但不限于APP、PC、H5PAD、端，及以链接、OpenAPI等方式接入的小程序、公众号或第三方媒体账号等形式。</p>
    <p
      class="s1"
    >1.2 The“Vancheen”here refers to the platform operated by Prolinker Holding (Guangzhou) Co., Ltd. and / or affiliated enterprises and enjoying intellectual property rights or other rights to provide business activities such as commodity or service information display, online services and online commodity trading. The product forms include but are not limited to app, PC, H5PAD, port, and small programs accessed by means of link and OpenAPI, and the official account or the third party media account.</p>
    <p
      class="s1"
    >1.3 供应商：是指通过Vancheen平台发布商品，并委托您推广其商品，向您提供以低于市场平均价的合格产品，以为您提供提成利润空间作为您推广商品及服务的推广费用，并处理您顾客的售后问题。</p>
    <p
      class="s1"
    >1.3 The “supplier” here refers to the product released through the Vancheen platform and entrusted to you to promote its products, provide you with qualified products at a price lower than the average market price, provide you with a commission profit space as the promotion expenses for your promotion of goods and services, and deal with the after-sales problems of your customers.</p>
    <p class="s1">1.4顾客 ：是基于您的推广与服务行为而在您的店铺下单的消费者。</p>
    <p
      class="s1"
    >1.4 The “customers”here refers to consumers who place orders in your online store based on your promotion and service behavior.</p>
    <p class="s1">1.5 关联企业：是指与我们之间存在直接或间接控制关系或其实际控制人相同或具有重大影响关系的企业。</p>
    <p
      class="s1"
    >1.5 The “affiliated enterprise” here refers to an enterprise that has a direct or indirect control relationship with us or has the same or significant influence as its actual controller.</p>
    <p
      class="s1"
    >1.6 关联产品：是指由迎客商服（广州）控股有限公司及关联企业开发或运营的所有产品，产品形式包括但不限于网站、H5网页、移动客户端（APP）、PAD端、小程序、公众号及其他第三方媒体账号等，具体由我们负责释义。</p>
    <p
      class="s1"
    >1.6 The “related products” here refers to all products developed or operated by Prolinker Holding (Guangzhou) Co., Ltd. and associated enterprises, including, but not limited to, websites, H5 web pages, mobile client-end(APP), PAD terminal, small programs, WeChat Subscription and other third party media accounts.</p>
    <p
      class="s1"
    >1.7 平台规则 ：是指已经发布及后续发布的所有规则、解读、声明、公告、通知、警示、提示、说明、管理规范等内容，以及Vancheen在消息中心等功能模块内发布的各类规则、实施细则、流程说明、解释等内容。</p>
    <p
      class="s1"
    >1.7 The “platform rules”here refers to all rules, interpretations, statements, announcements, notices, warnings, tips, instructions, management specifications and other contents that have been published and subsequently published, as well as various rules, implementation rules, process descriptions, explanations and other contents published by Vancheen in functional modules such as the message center.</p>
    <h2 class="sTitle">二、协议范围、生效和变更</h2>
    <h2 class="sTitle">2、 Scope, effectiveness and changes of the Agreement</h2>
    <p
      class="s1"
    >2.1 本协议是确定您同意商家的推广委托，使用我们Vancheen产品开展商品推广服务、促成商品交易并获得推广服务收入的依据，关于您注册、登陆、使用Vancheen产品功能或服务、接受商家委托推广商品、促成商品销售并活动推广收入的所有活动，均依据本协议的约定执行。</p>
    <p
      class="s1"
    >2.1 This agreement is the basis for determining that you agree to the merchant's promotion entrustment, use our Vancheen products to carry out commodity promotion services, promote transactions and obtain promotion service income. All activities related to your registration, login, use of Vancheen product functions or services, acceptance of merchant entrustment to promote commodities, promote commodity sales and promote income, shall be implemented in accordance with the provisions of this agreement.</p>
    <p class="s1">2.2 本协议内容包括协议正文、《隐私政策》和平台规则，均为本协议不可分割的一部分，与本协议正文具有同等法律效力。</p>
    <p
      class="s1"
    >2.2 The contents of this agreement include the text of this agreement, Privacy Policy and platform rules, which are an integral part of this agreement and have the same legal effect as the text of this agreement.</p>
    <p
      class="s1"
    >2.3 如您在使用Vancheen过程中，明确由第三方通过链接、OpenAPI及其他方式接入服务的，您享受的相关接入服务的权利义务，由实际服务提供方与您另行签署协议约定。</p>
    <p
      class="s1"
    >2.3 In the process of using Vancheen, if you specify that a third party accesses the service through links, OpenAPI and other means, the rights and obligations of the relevant access services you enjoy shall be separately agreed by the actual service provider and you.</p>
    <p
      class="s1"
    >2.4 本协议项下，Vancheen经营者可能根据Vancheen的业务调整而发生变更，变更后的Vancheen经营者与您共同履行本协议，Vancheen经营者的变更不会影响您本协议项下的权益。</p>
    <p
      class="s1"
    >2.4 Under this agreement, the Vancheen operator may change according to the business adjustment of Vancheen. The changed Vancheen operator will perform this agreement together with you. The change of Vancheen operator will not affect your rights and interests under this agreement.</p>
    <p
      class="s1"
    >2.5 您同意，由于互联网商速发展，您与我们签署的本协议列明的条款并不能完整罗列并覆盖您与我们之间所有的权利与义务，现在的约定也不能保证完全符合未来发展的需求。我们有权基于国家法律法规变化、业务调整、产品更新以及维护交易秩序、保护电商生态参与者权益等需求，不时对本协议及其补充协议进行修改或变更。修改和变更后的协议将于生效或实施前7日予以公示，公示后的协议将完全替代修订前的协议，若您不同意修订后的协议，请您立即停止使用本产品，否则视为您已充分阅读、理解并接受变更后的协议内容。</p>
    <p
      class="s1"
    >2.5 You agree that due to the rapid development of Internet business, the terms set out in this agreement signed by you and us can not completely list and cover all the rights and obligations between you and us, and the current agreement can not guarantee to fully meet the needs of future development. We have the right to modify or change this Agreement and its supplementary agreement from time to time based on the needs of changes in national laws and regulations, business adjustment, product update, maintaining transaction order and protecting the rights and interests of e-commerce ecological participants. The amended and changed agreement will be publicized 7 days before its effectiveness or implementation, and the publicized agreement will completely replace the Agreement before the amendment. If you do not agree with the amended agreement, please stop using the product immediately, otherwise you will be deemed to have fully read, understood and accepted the content of the changed agreement.</p>
    <p class="s1">2.6 本协议其他条款未明示授权的其他一切权利由我们保留，如我们未行使前述任何权利，并不构成对该权利的放弃。</p>
    <p
      class="s1"
    >2.6 All other rights not expressly authorized by other terms of this Agreement are reserved by us. If we fail to exercise any of the above rights, it does not constitute a waiver of such rights.</p>
    <h2 class="sTitle">三、您的权利与责任</h2>
    <h2 class="sTitle">3、 Your rights and responsibilities</h2>
    <p
      class="s1"
    >3.1 您应按照Vancheen及关联产品注册流程要求填报信息，完成KYC认证，以成为店主。您在注册后，即视为您已同意接受商家的推广委托，使用Vancheen提供的各项产品服务与功能开展商品推广服务。</p>
    <p
      class="s1"
    >3.1 You should fill in the information according to the registration requirements of Vancheen and related products, complete KYC certification, and become a online shop keeper. After you register, it is deemed that you have agreed to accept the promotion entrustment of the merchant and use various products, services and functions provided by Vancheen to carry out commodity promotion services.</p>
    <p class="s1">
      3.2 您可以享受如下权利：
      <br /><br />3.2 You can enjoy the following rights:
      <br />
      <br />(1) 注册Vancheen账号并开通店铺，依据平台规则享受Vancheen及关联产品提供的各项权益；
      <br /><br />(1) Register the Vancheen account and open the online store, and enjoy the rights and interests provided by Vancheen and related products according to the platform rules;
      <br />
      <br />(2) 接受商家的推广委托，通过Vancheen进行商推广、分享商品信息、开展商品销售，并获得推广收入的权利；
      <br /><br />(2) Accept the promotion entrustment of merchants, carry out business promotion, share commodity information, carry out commodity sales through Vancheen, and have the right to obtain promotion income;
      <br />
      <br />(3) 使用Vancheen及其关联产品提供/推荐的推广渠道 、营销工具及消费者管理等各项功能或服务；
      <br /><br /> (3) Use the promotion channels, marketing tools, consumer management and other functions or services provided / recommended by Vancheen and its associated products;
      <br />
      <br />(4) 在遵守平台规则的情况下，自主选择店铺推广的商品或服务、对店铺命名及修改店铺名称的权利；
      <br /><br /> (4) The right to independently choose the goods or services promoted by the online store, name the store and modify the name of the store under the condition of abiding by the rules of the platform;
      <br />
      <br />(5) 基于商品或服务推广的目的，用适合销售群体使用的语言，二次编辑商家提供的商品图片、文字等推广所用的信息或内容； 
      <br /><br /> (5) Based on the purpose of commodity or service promotion, edit the commodity pictures, words and other information or content provided by the merchant in a language suitable for the user groups;
      <br />
      <br />(6) 其他您基于使用Vancheen平台而享有的各项权利。
      <br /><br /> (6) Other rights you enjoy based on using the Vancheen platform.
    </p>
    <p class="s1">
      <br />3.3 您使用Vancheen进行店铺经营活动时，应履行如下责任或义务：
      <br /><br />3.3 When using Vancheen for store business activities, you shall perform the following responsibilities or obligations:
      <br />
      <br />(1) 积极处理消费者的售前咨询及售后问题，传递正确的价值观与文化观，对消费者在店铺的行为进行监督与管理，并承担相应的监督管理责任；
      <br /><br />(1) Actively deal with consumers' pre-sales consultation and after-sales problems, convey correct values and cultural values, supervise and manage consumers' behavior in stores, and bear corresponding supervision and management responsibilities;
      <br />
      <br />(2) 妥善处理与各方的纠纷问题，包括但不限于消费者、商家及其他平台用户，并对店铺经营行为承担相应的法律责任；
      <br /><br />(2) Properly handle disputes with all parties, including but not limited to consumers, merchants and other platform users, and bear corresponding legal liabilities for store business behavior;
      <br />
      <br />(3) 对使用Vancheen过程中获知的各类信息承担保密义务与责任；
      <br /><br />(3) Undertake confidentiality obligations and responsibilities for all kinds of information obtained during the use of Vancheen;
      <br />
      <br />(4) 遵守店铺使用的规范，包括但不限于行为规范、命名规范及平台规则的内容；
      <br /><br />(4) Comply with the norms used by the store, including but not limited to the code of conduct, naming norms and platform rules;
      <br />
      <br />(5) 遵守并执行平台的各项考核方案、权益分配机制及营销活动规则等；
      <br /><br />(5) Abide by and implement various assessment schemes, equity distribution mechanism and marketing activity rules of the platform;
      <br />
      <br />(6) 其他依据协议内容、平台规则及法律、法规规定的应当由店主承担的责任。
      <br /><br />(6) Other responsibilities that should be borne by the shop keeper according to the contents of the Agreement, platform rules and laws and regulations.
    </p>
    <p class="s1">3.4 您应依照您所在国的法律规定，履行纳税义务，对销售额/营业所得，及时、足额地自行向所属地税务主管机关申报纳税。</p>
    <p
      class="s1"
    >3.4 You shall fulfill your tax obligations in accordance with the laws and regulations of your country, and timely and fully report and pay tax to the local competent tax authority for sales / business income.</p>
    <p
      class="s1"
    >3.5 为了改善您的产品体验、完善服务内容、保证Vancheen服务的安全性和功能的一致性等原因，我们将不断努力开始或完善新的功能和服务，并有权不经向您特别通知而对产品进行更新，或者对产品部分功能效果进行改变或限制（可能会采取软件替换、修改、功能强化、版本升级等形式）。</p>
    <p
      class="s1"
    >3.5 In order to improve your product experience, improve the service content, and ensure the security and function consistency of Vancheen service, we will continue to make efforts to start or improve new functions and services, and have the right to update the product without special notice to you, or change or limit some function effects of the product (it may take the form of software replacement, modification, function enhancement, version upgrade, etc.).</p>
    <h2 class="sTitle">四、账号注册与使用</h2>
    <h2 class="sTitle">4、 Account registration and use</h2>
    <p class="s1">4.1.1 如您使用同一身份认证信息、同一手机号或经Vancheen排查认定多个Vancheen账户的实际控制人为同一人，均视为同一用户。</p>
    <p
      class="s1"
    >4.1.1 If you use the same identity authentication information, the same mobile phone number or the actual controller of multiple Vancheen accounts is the same person after Vancheen investigation, you are regarded as the same user.</p>
    <p
      class="s1"
    >4.1.2 为使您更好地使用Vancheen的各项产品功能和服务，保障您的账号安全，Vancheen可要求您按照法律规定完成实名认证，如不及时完成实名认证，您将会受到Vancheen产品功能和服务的部分或全部的使用限制。</p>
    <p
      class="s1"
    >4.1.2 In order to enable you to make better use of Vancheen's product functions and services and ensure the security of your account, Vancheen may require you to complete the real name authentication in accordance with the law. If you do not complete the real name authentication in time, you will be subject to some or all restrictions on the use of Vancheen's product functions and services.</p>
    <p class="s1">4.1.3 您了解并同意，您有义务保证您提供信息（包括身份验证信息）的真实性及有效性。</p>
    <p
      class="s1"
    >4.1.3 You understand and agree that you are obliged to ensure the authenticity and validity of the information you provide (including authentication information).</p>
    <h2 class="sTitle">4.2 账号安全</h2>
    <h2 class="sTitle">4.2 Account Security</h2>
    <p
      class="s1"
    >4.2.1 您的账号为您自行设置和保管，建议您务必管理好您的账号信息（包括但不限于：头像、昵称、店铺名称、真实姓名、身份证号码、联系地址、交易订单信息、银行账号信息等），并确认您结束Vancheen使用时退出登录并以正确步骤离开Vancheen。除Vancheen存在过错外，您应对您账号项下的所有行为结果（包括但不限于在线签署各类协议、发布信息、商品交易行为、评价行为和信息披露等）负责。账号因您主动泄露或因您遭受他人攻击、诈骗等行为导致的损失及后果，Vancheen并不承担责任，您应通过司法、行政等救济途径向侵权行为人追偿。</p>
    <p
      class="s1"
    >4.2.1 Your account is set up and kept for you. It is recommended that you manage your account information (including but not limited to: faces, nickname, shop name, real name, ID number, contact address, order information, bank account information, etc.), and confirm that when you log off in the correct steps. Except for Vancheen's fault, you shall be responsible for all behavior results under your account (including but not limited to signing various agreements online, publishing information, commodity trading behavior, evaluation behavior and information disclosure, etc.). Vancheen shall not be liable for the losses and consequences caused by your active disclosure of the account or your attack and fraud by others. You shall recover from the infringer through judicial, administrative and other remedies.</p>
    <p
      class="s1"
    >4.2.2 由于您的Vancheen账号关联您的个人信息及Vancheen的商业信息，您的账号仅限您本人使用。未经Vancheen同意，您直接或间接授权第三方使用您Vancheen账号或获取您账号项下信息的行为视为您本人的行为，由您本人承担全部法律责任。如根据平台规则判断您账号的使用行为可能危及您的账号安全及/或Vancheen信息安全的，Vancheen有限拒绝您继续使用产品功能或终止本协议。</p>
    <p
      class="s1"
    >4.2.2 Because your Vancheen account is associated with your personal information and Vancheen's business information, your account can only be used by yourself. Without the consent of Vancheen, your direct or indirect authorization to a third party to use your Vancheen account or obtain the information under your account shall be regarded as your own behavior, and you shall bear all legal liabilities. If it is judged that the use of your account may endanger your account security and / or Vancheen information security according to the platform rules, Vancheen limited refuses you to continue to use the product functions or terminate this agreement.</p>
    <p
      class="s1"
    >4.2.3 如您发现任何未经授权使用您账号登录Vancheen或其他可能导致您账号遭窃、遗失的情况，建议您立即通知Vancheen客服，并授权Vancheen将该信息同步给其他第三方。您理解Vancheen对您的任何请示采取行动均需要合理时间，且Vancheen应您请求而采取的行动可能无法避免或阻止侵害后果的形成或扩大，除Vancheen存在法定过错外，由您自行承担您账号的保管责任。</p>
    <p
      class="s1"
    >4.2.3 If you find any unauthorized use of your account to log in to Vancheen or other situations that may lead to the theft and loss of your account, it is recommended that you immediately notify Vancheen customer service and authorize Vancheen to synchronize the information to other third parties. You understand that it takes a reasonable time for Vancheen to take action on any request from you, and the actions taken by Vancheen at your request may not avoid or prevent the formation or expansion of infringement consequences. Unless Vancheen has legal fault, you shall bear the custody responsibility of your account number by yourself.</p>
    <p
      class="s1"
    >4.2.4 如您在使用Vancheen产品过程中获知他方信息（包括但不限于消费者、其他店铺使用者、商家、Vancheen及关联产品有关的所有数据或内容），您均应尽到保密义务与责任，在未经授权的情况下，您不得以任何形式披露上述信息，否则您将承担泄密责任。</p>
    <p
      class="s1"
    >4.2.4 If you know other party's information (including but not limited to all data or contents related to consumers, other store users, merchants, Vancheen and related products) in the process of using Vancheen products, you shall fulfill the obligation and responsibility of confidentiality. You shall not disclose the above information in any form without authorization, otherwise you will bear the responsibility of disclosure.</p>
    <h2 class="sTitle">4.3 账号回收</h2>
    <h2 class="sTitle">4.3 Account Recovery</h2>
    <p class="s1">4.3.1 如您存在违反法律法规及/或平台规则的情形，则我们可回收您的账户，您的账户将不能再登陆任一产品形式的Vancheen，相应服务同时终止。</p>
    <p
      class="s1"
    >4.3.1 If you violate laws and regulations and / or platform rules, we can reclaim your account. Your account will no longer be able to log in to Vancheen in any product form, and the corresponding services will be terminated at the same time.</p>
    <h2 class="sTitle">五、你的行为规范</h2>
    <h2 class="sTitle">5、 Your code of conduct</h2>
    <p class="s1">
      5.1信息内容发布规范
      <br />
      <br />5.1 Information content release specification
      <br />
      <br />5.1.1您理解并同意，Vancheen致力于为用户提供健康、可持续发展的电子商务交易环境，您不得利用Vancheen产品功能及服务制作、复制、发布、传播、传送、储存如下干扰Vancheen正常运营、侵犯Vancheen及其他用户或第三方合法权益的内容，包括但不限于：
      <br />
      <br />5.1.1 You understand and agree that Vancheen is committed to providing users with a healthy and sustainable e-commerce trading environment. You shall not use Vancheen product functions and services to make, copy, publish, disseminate, transmit and store the following contents that interfere with Vancheen's normal operation and infringe upon the legitimate rights and interests of Vancheen and other users or third parties, including but not limited to:
    </p>
    <p class="s1">
      5.1.1.1发布、传送、传播、储存违反中国及您所属国的法律法规禁止的内容：
      <br />1）违反中国宪法确定的基本原则的；
      <br />2）危害任意国家安全，泄露任意国家秘密，颠覆任意国家政权，破坏任意国家统一的；
      <br />3）损害任意国家荣誉和利益的；
      <br />4）煽动民族仇恨、民族歧视，破坏民族团结的；
      <br />5）破坏国家宗教政策，宣扬邪教和封建迷信的；
      <br />6）散布谣言，扰乱社会秩序，破坏社会稳定的；
      <br />7）散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；
      <br />8）侮辱或者诽谤他人，侵害他人合法权益的；
      <br />9）煽动非法集会、结社、游行、示威、聚众扰乱社会秩序；
      <br />10）以非法组织名义活动的；
      <br />11）含有法律法规禁止的其他内容的。
      <br />
      <br />5.1.1.1 Publishing, transmitting, disseminating and storing contents prohibited by laws and regulations of China and your country:
      <br />1) Violating the basic principles defined in the Chinese constitution;
      <br />2) Endangering any national security, divulging any state secrets, subverting any state power and undermining any national unity;
      <br />3) Harming the honor and interests of any state;
      <br />4) Inciting national hatred and discrimination and undermining national unity;
      <br />5) Undermining state religious policies and propagating cults and feudal Superstitions;
      <br />6) Spreading rumors, disturbing social order and undermining social stability;
      <br />7) Spreading obscenity, pornography, gambling, violence, terror or abetting crime;
      <br />8) Insulting or slandering others and infringing upon the legitimate rights and interests of others;
      <br />9) Inciting illegal assembly, association, procession, demonstration or gathering people to disturb social order;
      <br />10) Activities in the name of an illegal organization;
      <br />11) Containing other contents prohibited by laws and regulations.
    </p>
    <p class="s1">5.1.1.2 侵害他人名誉权、肖像权、姓名权、知识产权、商业秘密等合法权利的内容；</p>
    <p
      class="s1"
    >5.1.1.2 Contents that infringe upon others' reputation right, portrait right, name right, intellectual property right, trade secret and other legal rights;</p>
    <p class="s1">5.1.1.3 涉及他人隐私、个人信息或资料的；</p>
    <p class="s1">5.1.1.3 Involving the privacy, personal information or data of others;</p>
    <p class="s1">5.1.1.4 含有辱骂、恐吓、威胁内容的；</p>
    <p class="s1">5.1.1.4 Abusive, intimidating and threatening contents;</p>
    <p class="s1">5.1.1.5 含有骚扰、垃圾广告、恶意信息、诱骗信息的；</p>
    <p
      class="s1"
    >5.1.1.5 It contains harassment, spam advertising, malicious information and deception information;</p>
    <p class="s1">5.1.1.6 过度营销信息及垃圾信息或含有任何性或性暗示的；</p>
    <p
      class="s1"
    >5.1.1.6 Excessive marketing information and junk information or containing any sexual or suggestive information;</p>
    <p class="s1">5.1.1.7 其他违反法律法规、政策、社会公德、干扰Vancheen正常运营和侵犯其他用户或第三方合法权益内容的信息。</p>
    <p
      class="s1"
    >5.1.1.7 Other information that violates laws and regulations, policies, social ethics, interferes with the normal operation of Vancheen and infringes on the legitimate rights and interests of other users or third parties.</p>
    <p class="s1">5.2 Vancheen使用规范</p>
    <p class="s1">5.2 The usage specification of Vancheen</p>
    <p class="s1">5.2.1 除法律允许或Vancheen的书面许可，您在使用Vancheen过程中不得从事下列行为：</p>
    <p
      class="s1"
    >5.2.1 Except as permitted by law or with the written permission of Vancheen, you shall not engage in the following acts in the process of using Vancheen:</p>
    <p class="s1">5.2.1.1 删除Vancheen上关于著作权的信息；</p>
    <p class="s1">5.2.1.1 Delete the information about copyright on Vancheen;</p>
    <p class="s1">5.2.1.2 对Vancheen进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现或获取Vancheen的源代码；</p>
    <p
      class="s1"
    >5.2.1.2 Reverse engineer, reverse assemble, reverse compile Vancheen, or try to find or obtain Vancheen's source code in other ways;</p>
    <p class="s1">5.2.1.3 对Vancheen经营者拥有的知识产权内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；</p>
    <p
      class="s1"
    >5.2.1.3 Use, rent, lend, copy, modify, link, reprint, compile, publish, publish and establish mirror sites for the intellectual property content owned by Vancheen operators;</p>
    <p
      class="s1"
    >5.2.1.4 对Vancheen运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及本软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、非经Vancheen授权的第三方工具/服务接入本软件和相关系统；</p>
    <p
      class="s1"
    >5.2.1.4 Copy, modify, add, delete, connect, run or create any derivative works for the data released to any terminal memory during Vancheen operation, the interactive data between client and server during software operation, and the system data necessary for the operation of the software, including but not limited to the use of plug-ins Access to the software and related systems by third-party tools / services not authorized by Vancheen;</p>
    <p
      class="s1"
    >5.2.1.5 通过修改或伪造Vancheen运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；</p>
    <p
      class="s1"
    >5.2.1.5 Add, delete and change the function or operation effect of the software by modifying or forging the instructions and data in the operation of Vancheen, or operate or disseminate the software and methods used for the above purposes to the public, whether or not these acts are for commercial purposes;</p>
    <p class="s1">5.2.1.6 自行或者授权他人、第三方软件对Vancheen的软件及其组件、模块、数据进行干扰；</p>
    <p
      class="s1"
    >5.2.1.6 Interfere with Vancheen's software and its components, modules and data by itself or by authorizing others and third-party software;</p>
    <p class="s1">5.2.1.7 其他未经Vancheen明示授权的行为。</p>
    <p class="s1">5.2.1.7 Other acts not expressly authorized by Vancheen.</p>
    <p class="s1">
      5.3 您不得利用Vancheen进行如下行为：
      <br /><br />5.3 You must not use Vancheen to:
      <br /><br />5.3.1 提交、发布虚假信息，或盗用他人头像或资料，冒充、利用他人名义的；
      <br /><br />5.3.1 Submitting and publishing false information, or embezzling other people's avatars or materials, posing as or using other people's names;
      <br /><br />5.3.2 虚构事实、隐瞒真相以误导、欺骗他人的；
      <br /><br />5.3.2 Making up facts or concealing the truth to mislead or deceive others;
      <br /><br />5.3.3 利用技术手段批量建立虚假账号的；
      <br /><br />5.3.3 Using technical means to establish false account numbers in batches;
      <br /><br />5.3.4 利用Vancheen从事任何违法犯罪活动的；
      <br /><br />5.3.4 Using Vancheen to engage in any illegal and criminal activities;
      <br /><br />5.3.5 制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播，无论这些行为是否为商业目的；
      <br /><br />5.3.5 Make, publish, operate or disseminate methods and tools related to the above acts, whether or not these acts are for commercial purposes;
      <br /><br />5.3.6 对Vancheen或其他用户进行恶意不正当竞争的行为；
      <br /><br />5.3.6 Conduct malicious and unfair competition against Vancheen or other users;
      <br /><br />5.3.7 其他违反法律法规规定、侵犯其他用户合法权益、干扰Vancheen正常运营或Vancheen未明示授权行为。
      <br /><br />5.3.7 Other acts that violate laws and regulations, infringe upon the legitimate rights and interests of other users, interfere with the normal operation of Vancheen or are not expressly authorized by Vancheen.
    </p>
    <p class="s1">
      5.4 用户行为负责
      <br /><br />5.4 User behavior responsibility
      <br /><br />5.4.1 您充分了解并同意，您必须为自己注册账号下的一切行为负责，包括您的发布的任何内容以及通过Vancheen开展的所有活动行为，包括但不限于店铺管理、商品交易、营销、推广、培训及服务消费者的行为等，以及由此产生的任何后果。您应对Vancheen产品功能和服务中的内容自行加以判断，并承担因使用内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。Vancheen无法且不会对因前述风险而导致的任何损失或损害承担责任。
      <br /><br />5.4.1 You fully understand and agree that you must be responsible for all actions under your registered account, including any content you publish and all activities carried out through Vancheen, including but not limited to store management, commodity trading, marketing, promotion, training and serving consumers, as well as any consequences arising therefrom. You should make your own judgment on the content of Vancheen's product functions and services, and bear all risks arising from the use of the content, including those arising from reliance on the correctness, integrity or practicability of the content. Vancheen cannot and will not be liable for any loss or damage caused by the above risks.
    </p>
    <p class="s1">
      5.5 违约处理
      <br /><br />5.5 Handling of breach of contract
      <br /><br />5.5.1 如我们发现或收到他人举报或投诉您违反本协议或平台规则的，我们有权对被投诉或举报的行为进行独立判断，不经通知的随时对相关内容进行删除、屏蔽，并根据您的行为情节对您及/或您的店铺给予警告、禁言（包括违规账号及其关联账号）、限制或禁止使用部分或全部功能、暂时性或永久封禁违规及关联账号等处罚，并公告处理结果。
      <br /><br />5.5.1 If we find or receive reports or complaints from others about your violation of this agreement or platform rules, we have the right to make independent judgment on the behavior complained or reported, delete and block the relevant content at any time without notice, and give warnings and prohibitions to you and / or your store according to your behavior (including illegal account and its associated account) Restrict or prohibit the use of some or all functions, temporary or permanent ban violations and associated accounts, and announce the handling results.
      <br /><br />5.5.2 为适应电子商务发展和满足您对功能的需求，您理解并同意，我们有权设立违法违规行为处理的程序和标准，并有权在合理范围内对违反有关法律法规或本协议规定的行为进行认定和处理，并依据法律法规保存有关信息向有关部门报告等。
      <br /><br />5.5.2 In order to adapt to the development of e-commerce and meet your functional needs, you understand and agree that we have the right to establish procedures and standards for handling violations, identify and deal with violations of relevant laws and regulations or the provisions of this agreement within a reasonable range, save relevant information according to laws and regulations, and report to relevant departments.
      <br /><br />5.5.3 我们对投诉内容的审查仅为初步表面审查，不对审查结论承担责任，如果您对被投诉的内容存在导言，您应与投诉方接洽处理或采取法律途径解决争议。您可提交申诉，我们将对申诉进行审查，并自行合理判断决定是否变更处罚措施。对于因投诉所做出的处理，我们仍有权根据投诉方与您共同确定的意见或中国司法机关生效法律文书增加、变更或撤销对您及/或您的店铺的处理。
      <br /><br />5.5.3 Our review of the contents of the complaint is only a preliminary superficial review, and we will not be responsible for the review conclusion. If you have an introduction to the contents of the complaint, you should contact the complainant or take legal means to resolve the dispute. You can submit a complaint, and we will review the complaint and decide whether to change the punishment measures in our own reasonable judgment. For the handling of the complaint, we still have the right to increase, change or revoke the handling of you and / or your store according to the opinions jointly determined by the complainant and you or the legal documents in force by the Chinese judicial authorities.
      <br /><br />5.5.4 您理解并同意，因您违反本协议或平台规则的规定，导致或产生第三方主张的任何索赔、要求或损失，您应当独立承担责任。我们或关联方发生的任何损失，包括合理的律师费，您应当一并赔偿。您同意，我们可以将合作方、关联方的损失作为自身损失向您进行索赔，或者协助合作方、关联方向用户主张权利。
      <br /><br />5.5.4 You understand and agree that you shall be solely responsible for any claim, demand or loss claimed by a third party due to your violation of the provisions of this agreement or the platform rules. You should compensate for any losses incurred by us or related parties, including reasonable attorney fees. You agree that we can claim against you for the losses of partners and related parties as our own losses, or assist partners and related parties to claim rights from users.
    </p>
    <h2 class="sTitle">六、用户信息的保护及授权</h2>
    <h2 class="sTitle">6、 Protection and authorization of user information</h2>
    <p class="s1">
      6.1个人信息保护
      <br /><br />6.1 Personal information protection
      <br /><br />6.1.1 我们非常重视您的个人信息（即能够独立或与其他信息结合后识别您身份的信息）保护，在您使用Vancheen功能时，您同意我们按照《隐私政策》收集、存储、使用、披露和保护您的个人信息。我们建议您完整地阅读隐私权政策，以帮助您更好地保护您的隐私权。
      <br /><br />6.1.1 We attach great importance to the protection of your personal information (i.e. information that can identify you independently or in combination with other information). When you use the Vancheen function, you agree that we collect, store, use, disclose and protect your personal information in accordance with the privacy policy. We recommend that you read the privacy policy completely to help you better protect your privacy.
      <br /><br />6.2 非个人信息的保证与授权
      <br /><br />6.2 Guarantee and authorization of non personal information
      <br /><br />6.2.1 您声明并保证，您对您所发布的信息拥有相应、合法的权利。否则，我们可对您发布的信息依法或依本协议进行删除或屏蔽。
      <br /><br />6.2.1 You declare and guarantee that you have corresponding and legal rights to the information you publish. Otherwise, we may delete or block the information you publish according to law or this agreement.
      <br /><br />6.2.2 对于您所提供、发布及在使用Vancheen产品功能或服务中形成的除个人信息外的文字、图片、视频、音频等非个人信息，您永久免费授权我们及关联公司获得全球排他性许可使用及转授权给第三方使用的权利；并授权我们可以自身或授权的第三方名义对任何侵权行为取证及开展维权活动（包括但不限于行政投诉、举报及提起诉讼等）的权利。您同意并授权我们及关联公司存储、使用、复制、修改、编辑、发布、展示、翻译、分发、改变您的非个人信息或制作衍生作品的权利，并依法独立享有衍生作品及改编作品的著作权。
      <br /><br />6.2.2 For the text, picture, video, audio and other non personal information other than personal information provided, published and formed in the use of Vancheen product functions or services, you permanently authorize us and our affiliates to obtain the global exclusive license and sublicense to a third party for free; And authorize us to obtain evidence of any infringement and carry out rights protection activities (including but not limited to administrative complaints, reports and litigation) in the name of ourselves or an authorized third party. You agree and authorize us and our affiliates to store, use, copy, modify, edit, publish, display, translate, distribute and change your non personal information or make derivative works, and independently enjoy the copyright of derivative works and adapted works according to law.
    </p>
    <h2 class="sTitle">七、知识产权声明</h2>
    <h2 class="sTitle">7、 Intellectual property declaration</h2>
    <p class="s1">
      7.1 Vancheen的一切著作权、商标权、专利权、商业秘密等知识产权，以及与Vancheen相关的所有信息内容（包括但不限于文字、图片、音频、视频、图表、界面设计、版面框架、有关数据或电子文档等）均受中华人民共和国法律法规和相应的国际条约保护，我们依法享有上述知识产权，但相关权利人依照法律规定应享有的权利除外。
      <br /><br />7.1 All copyright, trademark right, patent right, trade secret and other intellectual property rights of Vancheen, as well as all information content related to Vancheen (including but not limited to text, pictures, audio, video, charts, interface design, layout framework, relevant data or electronic documents, etc.) are protected by the laws and regulations of the people's Republic of China and corresponding international treaties, We enjoy the above intellectual property rights in accordance with the law, except for the rights that relevant obligees should enjoy in accordance with the law.
      <br /><br />7.2 未经我们或相关权利人书面同意，您不得为任何商业或非商业目的自行或许可任何第三方实施、利用、转让上述知识产权。
      <br /><br />7.2 Without the written consent of us or relevant obligees, you shall not implement, utilize or transfer the above intellectual property rights by yourself or by licensing any third party for any commercial or non-commercial purposes.
    </p>
    <h2 class="sTitle">八、第三方软件或技术</h2>
    <h2 class="sTitle">8、 Third party software or technology</h2>
    <p class="s1">
      8.1 Vancheen可能会使用第三方软件或技术（包括Vancheen可能使用的开源代码和公共领域代码等，下同），这种使用已经获得合法授权。
      <br /><br />8.1 Vancheen may use third-party software or technology (including open source code and public domain code that Vancheen may use, the same below), and such use has been legally authorized.
      <br /><br />8.2 Vancheen如使用了第三方的软件或技术，我们将按照相关法规或约定，对相关的协议或其他文件，可能通过本协议附件、在Vancheen安装包特定文件夹中打包页面等形式进行展示，展示形式可能会以“软件使用许可协议”、“授权协议”或其他形式来表达。前述内容均是本协议不可分割的组成部分，与本协议具有同等的法律效力，您应当遵守这些要求。如果您没有遵守这些要求而造成第三方损失的，该第三方或者国家机关可能会对您提起诉讼、罚款或者采取其他制裁措施，您应当自行承担法律责任。
      <br /><br />8.2 If Vancheen uses the software or technology of a third party, we will display the relevant agreements or other documents in the form of annexes to this agreement, packaging pages in the specific folder of Vancheen installation package, etc. in accordance with relevant laws and regulations or agreements. The display form may be expressed in "software use license agreement", "License Agreement" or other forms. The foregoing contents are an integral part of this Agreement and have the same legal effect as this agreement. You should comply with these requirements. If you fail to comply with these requirements and cause losses to a third party, the third party or state organ may file a lawsuit, impose a fine or take other sanctions against you, and you shall bear the legal responsibility by yourself.
      <br /><br />8.3 如您因使用第三方软件或技术引发的任何纠纷，应由该第三方负责解决，若需要获取支持，请与第三方联系。我们不对第三方软件或技术提供客服支持及承担任何责任。
      <br /><br />8.3 Any dispute arising from your use of third-party software or technology shall be resolved by the third party. If you need support, please contact the third party. We do not provide customer service support and assume any responsibility for third-party software or technology.
    </p>
    <h2 class="sTitle">九、特别约定</h2>
    <h2 class="sTitle">9、 Special agreement</h2>
    <p class="s1">
      9.1 如您向我们及/或关联公司的雇员或顾问等提供实物、现金、现金等价物、劳务、旅游等价值明显超出正常商务洽谈范畴的利益，则可视为您存在商业贿赂行为。发生上述情形的，我们可立即终止与您的所有合作并向您收取违约金及/或赔偿金，该金额以我们因您的贿赂行为而遭受的经济损失和商誉损失作为计算依据。
      <br /><br />9.1 If you provide benefits in kind, cash, cash equivalents, labor services, tourism and other benefits whose value obviously exceeds the scope of normal business negotiation to employees or consultants of us and / or affiliated companies, it can be regarded as your commercial bribery. In case of the above circumstances, we can immediately terminate all cooperation with you and collect liquidated damages and / or compensation from you. The amount is calculated based on the economic losses and goodwill losses suffered by us due to your bribery.
      <br /><br />9.2 您在使用Vancheen的过程中，应当本着诚信、公平、公正的原则，如实向消费者推广商品信息，不得利用Vancheen开展非法活动，强迫交易。亦不得开展侵犯Vancheen权益的活动，如您对Vancheen造成损失的，Vancheen有权采取冻结账户余额、限制您对Vancheen全部或部分功能的使用，并有权要求您承担法律责任。
      <br /><br />9.2 In the process of using Vancheen, you should faithfully promote commodity information to consumers based on the principles of integrity, fairness and impartiality, and shall not use Vancheen to carry out illegal activities and force transactions. And shall not carry out activities that infringe upon Vancheen's rights and interests. If you cause losses to Vancheen, Vancheen has the right to freeze the account balance, restrict your use of all or part of Vancheen's functions, and require you to bear legal liabilities.
    </p>
    <h2 class="sTitle">十、有限责任</h2>
    <h2 class="sTitle">10、 Limited liability</h2>
    <p class="s1">
      10.1您了解并同意，鉴于现有技术水平和客观条件的限制，我们将采取一切可能的技术手段保持您使用Vancheen功能或服务所涉的技术和信息的有效性、准确性、可靠性、质量、稳定、完整和及时性，但我们对此不作任何承诺或保证。
      <br /><br />10.1 You understand and agree that in view of the limitations of the existing technical level and objective conditions, we will take all possible technical means to maintain the effectiveness, accuracy, reliability, quality, stability, integrity and timeliness of the technology and information involved in your use of Vancheen functions or services, but we do not make any commitment or guarantee.
      <br /><br />10.2我们依照法律规定履行基础保障义务，但对于下述原因导致的店铺所订立的商品交易合同（商品交易订单）履行障碍、履行瑕疵、履行延后或履行内容变更等情形，我们不承担任何责任：
      <br /><br />（1）因自然灾害、罢工、暴乱、战争、政府行为、司法行政命令等不可抗力因素；
      <br /><br />（2）因互联网网络连接故障，电力供应故障，电脑、通讯网络故障等公共服务因素或第三人因素；
      <br /><br />（3）因常规或紧急的设备与系统维护、设备与系统故障、网络信息与数据安全等因素；
      <br /><br />（4）因符合国家法律规定或合规性要求，对Vancheen进行合理优化的调整因素；
      <br /><br />（5）其他依据国家法律法规可以免责的情形。
      <br /><br />10.2 We perform our basic guarantee obligations in accordance with the law, but we will not bear any responsibility for the obstacles, defects, delays or changes in the content of the commodity trading contract (commodity trading order) concluded by the store due to the following reasons:
      <br /><br />(1) Due to natural disasters, strikes, riots, wars, government actions, judicial administrative orders and other force majeure factors;
      <br /><br />(2) Public service factors or third party factors such as Internet network connection failure, power supply failure, computer and communication network failure;
      <br /><br />(3) Due to routine or emergency equipment and system maintenance, equipment and system failure, network information and data security and other factors;
      <br /><br />(4) Adjustment factors for reasonable optimization of Vancheen due to compliance with national laws and regulations or compliance requirements;
      <br /><br />(5) Other circumstances that can be exempted according to national laws and regulations.
    </p>
    <h2 class="sTitle">十一、通知和送达</h2>
    <h2 class="sTitle">11、 Notice and delivery</h2>
    <p class="s1">
      11.1 您应确保在注册Vancheen时绑定的电子邮箱为您的有效联系方式，您有义务保证联系方式保持可以被联系的状态。我们将可能通过上述联系方式发布通知，此类通知的内容可能对您的权利义务产生重大的影响，请您务必及时关注。
      <br /><br />11.1 You should ensure that the email address bound when registering Vancheen is your valid contact information, and you are obliged to ensure that the contact information can be contacted. We may issue notices through the above contact information. The contents of such notices may have a significant impact on your rights and obligations. Please pay attention to them in time.
      <br /><br />11.2 您有权通知注册时绑定的电子邮箱获取您感兴趣的商品广告信息、促销优惠及各项活动等商业性信息。
      <br /><br />11.2 You have the right to notify the e-mail bound at the time of registration to obtain commercial information such as commodity advertising information, promotional offers and various activities you are interested in.
      <br /><br />11.3 Vancheen通过上述联系方式向您发出通知，也可采用Vancheen公告等方式通知，且该等通知自发送之日起7天后视为已向您送达或生效。
      <br /><br />11.3 Vancheen can send notice to you through the above contact information or Vancheen announcement, and such notice shall be deemed to have been served or effective 7 days after the date of sending.
    </p>
    <h2 class="sTitle">十二、协议的终止</h2>
    <h2 class="sTitle">12、 Termination of agreement</h2>
    <p class="s1">
      12.1您有权通过以下任一方式终止本协议：
      <br /><br />1）按平台规则注销您的账号的；
      <br /><br />2）变更事项生效前您停止使用并明示不愿意接受变更事项的；
      <br /><br />3）符合本协议约定的终止条件的。
      <br /><br />12.1 You have the right to terminate this agreement in any of the following ways:
      <br /><br />1) Cancel your account according to the platform rules;
      <br /><br />2) Before the change takes effect, you stop using and express your unwillingness to accept the change;
      <br /><br />3) It meets the termination conditions agreed in this agreement.
      <br /><br />12.2 出现以下情况时，Vancheen可以本协议第十一条所列的方式通知您终止本协议：
      <br /><br />1）您违反协议内容的约定，我们依据违约条款终止本协议的；
      <br /><br />2）您盗用他人的账号、发布违禁信息、骗取他人财务、扰乱市场秩序、采取不正当手段谋利等行为，我们依据平台规则对您的账号予以查封的；
      <br /><br />3）因您多次违反平台规则相关规定且情节严重的，Vancheen有权对您的账号予以查封的；
      <br /><br />4）您在Vancheen有欺诈、发布侵犯他人合法权益或其他严重违法违约行为的；
      <br /><br />5）其他应当终止本协议的情况。
      <br /><br />12.2 Vancheen may terminate this agreement by notifying you in the manner listed in Article 11 of this agreement in the following cases:
      <br /><br />1) If you violate the Agreement, we terminate this agreement according to the terms of breach;
      <br /><br />2) You embezzle other people's accounts, publish prohibited information, defraud other people's finance, disrupt market order, take improper means for profit, etc., and we seal your account according to the platform rules;
      <br /><br />3) Vancheen has the right to seal up your account because you have repeatedly violated the relevant provisions of the platform rules and the circumstances are serious;
      <br /><br />4) You commit fraud, publishing, infringing upon the legitimate rights and interests of others or other serious violations of laws and regulations in Vancheen;
      <br /><br />5) Other circumstances under which this Agreement shall be terminated.
      <br /><br />12.3 本协议终止后，我们仍享有下列权利：
      <br /><br />1）根据中华人民共和国法律法规或本协议的约定，继续保存您留存在Vancheen的相关信息；
      <br /><br />2）对于您过往的违约及/或违规行为，Vancheen仍可依据本协议或平台规则向您追究违约责任。
      <br /><br />12.3 After the termination of this agreement, we still have the following rights:
      <br /><br />1) Continue to keep the relevant information you left in Vancheen in accordance with the laws and regulations of the people's Republic of China or the Agreement;
      <br /><br />2) For your past breach of contract and / or violation, Vancheen can still pursue the liability for breach of contract against you in accordance with this agreement or the platform rules.
    </p>
    <h2 class="sTitle">十三、法律适用、管辖与其他</h2>
    <h2 class="sTitle">13、 Application of law, jurisdiction and others</h2>
    <p class="s1">
      13.1 本协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国大陆地区法律；如法律无相关规定的，参照国际商业惯例及/或国际行业惯例。
      <br /><br />13.1 The conclusion, effectiveness, interpretation, amendment, supplement, termination, execution and dispute resolution of this Agreement shall be governed by the laws of the mainland of the people's Republic of China; If there are no relevant provisions in the law, refer to international business practices and / or international industry practices.
      <br /><br />13.2 您因使用Vancheen产生的有关争议，由我们与您协商解决。协商不成时，任何一方均可向协议签订地即中华人民共和国广东广州人民法院提起诉讼。
      <br /><br />13.2 Any dispute arising from your use of Vancheen shall be settled by us through negotiation with you. If the negotiation fails, either party may bring a lawsuit to the people's Court of Guangzhou, Guangdong, the people's Republic of China where the Agreement is signed.
      <br /><br />13.3 本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且不影响本协议其余条款的有效性及可执行性。
      <br /><br />13.3 If any provision of this agreement is deemed to be annulled, invalid or unenforceable, it shall be deemed to be separable and shall not affect the validity and enforceability of other provisions of this agreement.
      <br /><br />13.4 本协议于2021年10月1日进行公示，自2021年10月1日起生效。
      <br /><br />13.4 This agreement will be publicized on October 1, 2021 and will take effect from October 1, 2021.
    </p>
    <div class="agreeBox" @click="gotoPrivacy">
      <div class="agree">{{$t.meta.privacy}}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Agreement",
  data() {
    return {};
  },
  created() {
    const { headerHide } = this.$route.query;
    if (headerHide) {
      this.headerHide = JSON.parse(headerHide);
      if (this.headerHide) {
        document.getElementsByClassName("van-nav-bar")[0].style.display =
          "none";
      }
    }
  },
  methods: {
    gotoPrivacy() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: false,
        path: "terms/Privacy",
        name: "Privacy",
        query: []
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.terms {
  width: 90%;
  margin: 0 auto;
  * {
    font-size: 11px;
  }
  .title {
    width: 100%;
    text-align: center;
    font-size: 13px;
    margin-top: 5%;
  }
  .titleInfo {
    width: 100%;
    text-align: center;
    margin: 10px 0;
  }
  .info {
    width: 100%;
    text-align: center;
    margin: 25px 0;
  }
  .s1 {
    text-align: left;
    margin: 25px 0;
  }
  .sTitle {
    text-align: left;
    margin: 25px 0;
  }
}
.agreeBox {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background: white;
  padding: 3% 0;
  text-align: center;
  border-top: 1px solid #e5e5e5;
  .agree {
    width: 80%;
    margin: auto;
    border-radius: 3rem;
    padding: 0.8rem 0;
    text-align: center;
    background: linear-gradient(left, #71d283, #01aaa3);
    color: #ffffff;
    font-size: 1rem;
    line-height: 1;
  }
}
</style>
